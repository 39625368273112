.commentList {
  .comment-form {
    margin-top: 1.3rem !important;
    margin-bottom: 1.3rem;
    width: 100%;
  }
  .comment-item {
    margin-bottom: 0.3rem;
  }
  .comment-head {
    color: @dark-color-opacity;
  }
  .comment-text {
    white-space: pre-line;
  }

  .avatar {
    background-color: @purple-color;
  }
  .actions {
    .ant-btn-link {
      padding-left: 0;
      color: @dark-color-opacity;
    }
  }
}

.comment {
  &-info {
    font-size: 18px;

    &-heading {
      font-size: 18px;
      margin: 15px 0;
    }
  }
}
