.item {
  overflow-wrap: break-word;
}
.white-space-nowrap {
  white-space: nowrap;
}
.max-content {
  width: max-content;
}
.candidateInfo .ant-typography.ant-typography-secondary {
  color: #ced4da;
  font-size: 12px;
}

.candidateInfo .ant-form-item-label > label,
.candidateInfo .item {
  font-size: 15px;
}

.overflow-visible .ant-form-item-label {
  overflow: inherit;
}
