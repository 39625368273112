.addCandidate {
  width: 100%;
}
.profile-create-wrap {
  padding: 0 20px;
  height: 100%;
  .button-block {
    position: sticky;
    bottom: 0;
    border-top: 1px solid #D2D2D7;
  }
}

.profile-create-block {
  padding-top: 15px;
  .ant-form-item-label{
    padding-bottom: 4px!important;
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
  .block-title{
    text-align: center;
  }
}
.second-block {
  height: 100%;
  padding-top: 15px;
  .name {
    padding-bottom: 10px;
    width: 100%;
  }
  h5 {
    width: 100%;
    text-align: center;
  }
  .ant-select-arrow {
    margin-top: -10px;
  }
  .ant-alert-message {
    color: #FFFFFF;
  }
  .ant-alert-warning {
    border-radius: 6px;
    background-color: #FF9500;
    margin-bottom: 15px;
    .ant-alert-icon{
      color: #FFFFFF;
    }
  }
}

