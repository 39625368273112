.departmentList {
  .green {
    color: #4caf51;
  }
  .priority-icon {
    width: 25px;
    height: 25px;
    background: url('../Candidate/img/priority.png') center;
    background-size: cover;
    display: flex;
    margin: auto;
  }
  .adult-icon {
    width: 20px;
    height: 20px;
    background: url('../Candidate/img/adult.png') center;
    background-size: cover;
    display: flex;
    margin: auto;
  }

    .ant-table.ant-table-bordered > .ant-table-footer {
        border: none;
        background: #ffffff;
        padding: 15px 0;
    }

    .ant-input-affix-wrapper {
        height: 32px;
    }

    .planningPersonal {
        .ant-table.ant-table-small .ant-table-thead > tr > th,
        .ant-table.ant-table-small .ant-table-tbody > tr > td {
            padding: 0 4px;
        }
        .ant-table.ant-table-small .ant-table-thead > tr > th:first-child,
        .ant-table.ant-table-small .ant-table-tbody > tr > td:first-child {
            padding: 0 4px 0 8px;
        }

        .text-ellipsis {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .ant-table-pagination.ant-pagination {
        display: none;
    }
    .ant-table.ant-table-small .ant-table-cell {
      font-size: 14px
    }
    .ant-table.ant-table-small .ant-table-cell .ant-typography.ant-typography-secondary {
      font-size: 11px
    }
}

.ant-table.ant-table-small .ant-table-tbody .planningPersonal .ant-table-wrapper:only-child .ant-table {
    margin: -8px;
}
.new-department {
  &.main__wrap {
    padding: 0;
  }
  .block-title {
    margin-top: 0;
  }
  .sub-title {
    margin-top: 32px;
    margin-bottom: 12px;
  }
  .ant-input:not(textarea) {
    height: 48px;
    border-radius: 4px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    border-radius: 4px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 48px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    height: 48px;
    display: flex;
    align-items: center;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    height: 48px;
    display: flex;
    align-items: center;
  }
}

