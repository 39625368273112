.Auth {
  .logo {
    width: 100%;
    height: 100vh;
    background: url('@{images-path}/logo-white.png') no-repeat center;
    background-size: 350px, 100%;
  }
  .logo-wrapper {
    background: @blue-color;
  }
  .title {
    text-align: center;
    font-size: 32px;
    font-weight: normal;
    margin: 0;
    color: @text-color;
  }
  .sub-title {
    margin: 45px 0 10px 25%;
    font-size: 15px;
    font-weight: normal;
  }
  input {
    height: 55px;
    font-size: 18px;
    //background-color: rgba(4, 161, 249, 0.19)!important;
  }
  input[type='password'] {
    height: 45px;
  }
  button {
    height: 55px;
    font-size: 25px;
  }
  .link {
    margin-top: 10px;
    text-align: center;
    a.danger {
      color: @danger-color;
    }
  }
  .auth-input-margin {
    margin: 20px 0 0;
  }
}
.ant-select.auth-role-selector {
  min-width: 200px;
}
// для кнопок авторизации и кнопок модалки на авторизации
button.ant-btn-primary {
  background-color: @blue-color;
  border-color: @blue-color;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: rgba(@blue-color, 0.8);
  border-color: @blue-color;
}
