.profile-action-loading {
  float: right;
  margin-right: 20px !important;
}

.profile-edit {
  float: right;

  i {
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;

    &:hover {
      color: #232323 !important;
    }
  }
}

.profile-close {
  position: absolute;
  right: 20px;

  i {
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;

    &:hover {
      color: #232323 !important;
    }
  }
}

